export const routes = [
  {
    path: '/',
    name: 'root',
    component: () => import('/home/app/www/releases/20240929082937/app/javascript/app/pages/index.vue'),
    /* no children */
    meta: {
      "auth": false,
      "layout": "default"
    }
  },
  {
    path: '/about',
    name: '/about',
    component: () => import('/home/app/www/releases/20240929082937/app/javascript/app/pages/about.vue'),
    /* no children */
    meta: {
      "layout": "default"
    }
  },
  {
    path: '/channels',
    /* internal name: '/channels' */
    /* no component */
    children: [
      {
        path: ':id',
        name: '/channels/[id]',
        component: () => import('/home/app/www/releases/20240929082937/app/javascript/app/pages/channels/[id].vue'),
        /* no children */
        meta: {
          "layout": "app"
        }
      },
      {
        path: ':id/edit',
        name: '/channels/[id].edit',
        component: () => import('/home/app/www/releases/20240929082937/app/javascript/app/pages/channels/[id].edit.vue'),
        /* no children */
        meta: {
          "layout": "app"
        }
      },
      {
        path: 'new',
        name: '/channels/new',
        component: () => import('/home/app/www/releases/20240929082937/app/javascript/app/pages/channels/new.vue'),
        /* no children */
        meta: {
          "layout": "app"
        }
      }
    ],
  },
  {
    path: '/dashboard',
    name: '/dashboard',
    component: () => import('/home/app/www/releases/20240929082937/app/javascript/app/pages/dashboard.vue'),
    /* no children */
    meta: {
      "layout": "app"
    }
  },
  {
    path: '/home',
    name: '/home',
    component: () => import('/home/app/www/releases/20240929082937/app/javascript/app/pages/home.vue'),
    /* no children */
    meta: {
      "layout": "default"
    }
  },
  {
    path: '/sign_in',
    name: 'sign_in',
    component: () => import('/home/app/www/releases/20240929082937/app/javascript/app/pages/sign_in.vue'),
    /* no children */
    meta: {
      "auth": false,
      "layout": "default"
    }
  }
]
