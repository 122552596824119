


export const layouts = {
'app/App': () => import('/home/app/www/releases/20240929082937/app/javascript/app/App.vue'),
'app/components/AppNavbar': () => import('/home/app/www/releases/20240929082937/app/javascript/app/components/AppNavbar.vue'),
'app/components/AppSidebar': () => import('/home/app/www/releases/20240929082937/app/javascript/app/components/AppSidebar.vue'),
'app/components/FormAlert': () => import('/home/app/www/releases/20240929082937/app/javascript/app/components/FormAlert.vue'),
'app/components/PageHeader': () => import('/home/app/www/releases/20240929082937/app/javascript/app/components/PageHeader.vue'),
'app/components/PageTitle': () => import('/home/app/www/releases/20240929082937/app/javascript/app/components/PageTitle.vue'),
'app/components/RLink': () => import('/home/app/www/releases/20240929082937/app/javascript/app/components/RLink.vue'),
'app/layouts/app': () => import('/home/app/www/releases/20240929082937/app/javascript/app/layouts/app.vue'),
'app/layouts/default': () => import('/home/app/www/releases/20240929082937/app/javascript/app/layouts/default.vue'),
'app/pages/HomeTable': () => import('/home/app/www/releases/20240929082937/app/javascript/app/pages/HomeTable.vue'),
'app/pages/about': () => import('/home/app/www/releases/20240929082937/app/javascript/app/pages/about.vue'),
'app/pages/dashboard': () => import('/home/app/www/releases/20240929082937/app/javascript/app/pages/dashboard.vue'),
'app/pages/home': () => import('/home/app/www/releases/20240929082937/app/javascript/app/pages/home.vue'),
'app/pages/index': () => import('/home/app/www/releases/20240929082937/app/javascript/app/pages/index.vue'),
'app/pages/sign_in': () => import('/home/app/www/releases/20240929082937/app/javascript/app/pages/sign_in.vue'),
'app/components/controls/InputNumber': () => import('/home/app/www/releases/20240929082937/app/javascript/app/components/controls/InputNumber.vue'),
'app/components/controls/InputPassword': () => import('/home/app/www/releases/20240929082937/app/javascript/app/components/controls/InputPassword.vue'),
'app/components/controls/InputText': () => import('/home/app/www/releases/20240929082937/app/javascript/app/components/controls/InputText.vue'),
'app/components/controls/Select': () => import('/home/app/www/releases/20240929082937/app/javascript/app/components/controls/Select.vue'),
'app/components/controls/Textarea': () => import('/home/app/www/releases/20240929082937/app/javascript/app/components/controls/Textarea.vue'),
'app/pages/channels/ChannelSourceCard': () => import('/home/app/www/releases/20240929082937/app/javascript/app/pages/channels/ChannelSourceCard.vue'),
'app/pages/channels/[id].edit': () => import('/home/app/www/releases/20240929082937/app/javascript/app/pages/channels/[id].edit.vue'),
'app/pages/channels/[id]': () => import('/home/app/www/releases/20240929082937/app/javascript/app/pages/channels/[id].vue'),
'app/pages/channels/new': () => import('/home/app/www/releases/20240929082937/app/javascript/app/pages/channels/new.vue'),
'vendor/ui/alert/Alert': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/alert/Alert.vue'),
'vendor/ui/alert/AlertDescription': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/alert/AlertDescription.vue'),
'vendor/ui/alert/AlertTitle': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/alert/AlertTitle.vue'),
'vendor/ui/alert-dialog/AlertDialog': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/alert-dialog/AlertDialog.vue'),
'vendor/ui/alert-dialog/AlertDialogAction': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/alert-dialog/AlertDialogAction.vue'),
'vendor/ui/alert-dialog/AlertDialogCancel': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/alert-dialog/AlertDialogCancel.vue'),
'vendor/ui/alert-dialog/AlertDialogContent': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/alert-dialog/AlertDialogContent.vue'),
'vendor/ui/alert-dialog/AlertDialogDescription': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/alert-dialog/AlertDialogDescription.vue'),
'vendor/ui/alert-dialog/AlertDialogFooter': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/alert-dialog/AlertDialogFooter.vue'),
'vendor/ui/alert-dialog/AlertDialogHeader': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/alert-dialog/AlertDialogHeader.vue'),
'vendor/ui/alert-dialog/AlertDialogTitle': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/alert-dialog/AlertDialogTitle.vue'),
'vendor/ui/alert-dialog/AlertDialogTrigger': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/alert-dialog/AlertDialogTrigger.vue'),
'vendor/ui/badge/Badge': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/badge/Badge.vue'),
'vendor/ui/button/Button': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/button/Button.vue'),
'vendor/ui/button/ButtonGroup': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/button/ButtonGroup.vue'),
'vendor/ui/card/Card': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/card/Card.vue'),
'vendor/ui/card/CardContent': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/card/CardContent.vue'),
'vendor/ui/card/CardDescription': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/card/CardDescription.vue'),
'vendor/ui/card/CardFooter': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/card/CardFooter.vue'),
'vendor/ui/card/CardHeader': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/card/CardHeader.vue'),
'vendor/ui/card/CardTitle': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/card/CardTitle.vue'),
'vendor/ui/card/CardTopState': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/card/CardTopState.vue'),
'vendor/ui/checkbox/Checkbox': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/checkbox/Checkbox.vue'),
'vendor/ui/checkbox/InputCheckbox': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/checkbox/InputCheckbox.vue'),
'vendor/ui/container/Container': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/container/Container.vue'),
'vendor/ui/dialog/Dialog': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/dialog/Dialog.vue'),
'vendor/ui/dialog/DialogClose': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/dialog/DialogClose.vue'),
'vendor/ui/dialog/DialogContent': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/dialog/DialogContent.vue'),
'vendor/ui/dialog/DialogDescription': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/dialog/DialogDescription.vue'),
'vendor/ui/dialog/DialogFooter': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/dialog/DialogFooter.vue'),
'vendor/ui/dialog/DialogHeader': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/dialog/DialogHeader.vue'),
'vendor/ui/dialog/DialogScrollContent': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/dialog/DialogScrollContent.vue'),
'vendor/ui/dialog/DialogTitle': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/dialog/DialogTitle.vue'),
'vendor/ui/dialog/DialogTrigger': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/dialog/DialogTrigger.vue'),
'vendor/ui/dropdown-menu/DropdownMenu': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/dropdown-menu/DropdownMenu.vue'),
'vendor/ui/dropdown-menu/DropdownMenuCheckboxItem': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/dropdown-menu/DropdownMenuCheckboxItem.vue'),
'vendor/ui/dropdown-menu/DropdownMenuContent': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/dropdown-menu/DropdownMenuContent.vue'),
'vendor/ui/dropdown-menu/DropdownMenuGroup': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/dropdown-menu/DropdownMenuGroup.vue'),
'vendor/ui/dropdown-menu/DropdownMenuItem': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/dropdown-menu/DropdownMenuItem.vue'),
'vendor/ui/dropdown-menu/DropdownMenuLabel': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/dropdown-menu/DropdownMenuLabel.vue'),
'vendor/ui/dropdown-menu/DropdownMenuRadioGroup': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/dropdown-menu/DropdownMenuRadioGroup.vue'),
'vendor/ui/dropdown-menu/DropdownMenuRadioItem': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/dropdown-menu/DropdownMenuRadioItem.vue'),
'vendor/ui/dropdown-menu/DropdownMenuSeparator': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/dropdown-menu/DropdownMenuSeparator.vue'),
'vendor/ui/dropdown-menu/DropdownMenuShortcut': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/dropdown-menu/DropdownMenuShortcut.vue'),
'vendor/ui/dropdown-menu/DropdownMenuSub': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/dropdown-menu/DropdownMenuSub.vue'),
'vendor/ui/dropdown-menu/DropdownMenuSubContent': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/dropdown-menu/DropdownMenuSubContent.vue'),
'vendor/ui/dropdown-menu/DropdownMenuSubTrigger': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/dropdown-menu/DropdownMenuSubTrigger.vue'),
'vendor/ui/dropdown-menu/DropdownMenuTrigger': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/dropdown-menu/DropdownMenuTrigger.vue'),
'vendor/ui/nav/NavItem': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/nav/NavItem.vue'),
'vendor/ui/nav/NavList': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/nav/NavList.vue'),
'vendor/ui/pagination/PaginationEllipsis': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/pagination/PaginationEllipsis.vue'),
'vendor/ui/pagination/PaginationFirst': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/pagination/PaginationFirst.vue'),
'vendor/ui/pagination/PaginationLast': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/pagination/PaginationLast.vue'),
'vendor/ui/pagination/PaginationNext': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/pagination/PaginationNext.vue'),
'vendor/ui/pagination/PaginationPrev': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/pagination/PaginationPrev.vue'),
'vendor/ui/progress/Progress': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/progress/Progress.vue'),
'vendor/ui/radio-group/InputRadio': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/radio-group/InputRadio.vue'),
'vendor/ui/radio-group/RadioGroup': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/radio-group/RadioGroup.vue'),
'vendor/ui/radio-group/RadioGroupItem': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/radio-group/RadioGroupItem.vue'),
'vendor/ui/select/Select': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/select/Select.vue'),
'vendor/ui/select/SelectContent': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/select/SelectContent.vue'),
'vendor/ui/select/SelectGroup': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/select/SelectGroup.vue'),
'vendor/ui/select/SelectItem': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/select/SelectItem.vue'),
'vendor/ui/select/SelectItemText': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/select/SelectItemText.vue'),
'vendor/ui/select/SelectLabel': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/select/SelectLabel.vue'),
'vendor/ui/select/SelectScrollDownButton': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/select/SelectScrollDownButton.vue'),
'vendor/ui/select/SelectScrollUpButton': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/select/SelectScrollUpButton.vue'),
'vendor/ui/select/SelectSeparator': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/select/SelectSeparator.vue'),
'vendor/ui/select/SelectTrigger': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/select/SelectTrigger.vue'),
'vendor/ui/select/SelectValue': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/select/SelectValue.vue'),
'vendor/ui/simple_form/Form': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/simple_form/Form.vue'),
'vendor/ui/simple_form/FormGroup': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/simple_form/FormGroup.vue'),
'vendor/ui/simple_form/PresenterConfigProvider': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/simple_form/PresenterConfigProvider.vue'),
'vendor/ui/table/Table': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/table/Table.vue'),
'vendor/ui/table/TableBody': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/table/TableBody.vue'),
'vendor/ui/table/TableCaption': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/table/TableCaption.vue'),
'vendor/ui/table/TableCell': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/table/TableCell.vue'),
'vendor/ui/table/TableEmpty': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/table/TableEmpty.vue'),
'vendor/ui/table/TableFooter': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/table/TableFooter.vue'),
'vendor/ui/table/TableHead': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/table/TableHead.vue'),
'vendor/ui/table/TableHeader': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/table/TableHeader.vue'),
'vendor/ui/table/TableRow': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/table/TableRow.vue'),
'vendor/ui/tabs/Tabs': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/tabs/Tabs.vue'),
'vendor/ui/tabs/TabsContent': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/tabs/TabsContent.vue'),
'vendor/ui/tabs/TabsList': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/tabs/TabsList.vue'),
'vendor/ui/tabs/TabsTrigger': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/tabs/TabsTrigger.vue'),
'vendor/ui/tooltip/Tooltip': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/tooltip/Tooltip.vue'),
'vendor/ui/tooltip/TooltipContent': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/tooltip/TooltipContent.vue'),
'vendor/ui/tooltip/TooltipProvider': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/tooltip/TooltipProvider.vue'),
'vendor/ui/tooltip/TooltipTrigger': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/tooltip/TooltipTrigger.vue'),
'vendor/ui/well/Well': () => import('/home/app/www/releases/20240929082937/app/javascript/vendor/ui/well/Well.vue'),
'app/components/controls/selectdrop/SelectdropItem': () => import('/home/app/www/releases/20240929082937/app/javascript/app/components/controls/selectdrop/SelectdropItem.vue'),
'app/components/controls/selectdrop/SelectdropMenuEntry': () => import('/home/app/www/releases/20240929082937/app/javascript/app/components/controls/selectdrop/SelectdropMenuEntry.vue'),
'app/components/controls/selectdrop/SelectdropMenuGroup': () => import('/home/app/www/releases/20240929082937/app/javascript/app/components/controls/selectdrop/SelectdropMenuGroup.vue'),
'app/components/controls/selectdrop/SelectdropMenuItem': () => import('/home/app/www/releases/20240929082937/app/javascript/app/components/controls/selectdrop/SelectdropMenuItem.vue'),
'app/components/controls/selectdrop/SelectdropMenuText': () => import('/home/app/www/releases/20240929082937/app/javascript/app/components/controls/selectdrop/SelectdropMenuText.vue'),
'app/components/controls/selectdrop/SelectdropRoot': () => import('/home/app/www/releases/20240929082937/app/javascript/app/components/controls/selectdrop/SelectdropRoot.vue'),
}
export const createGetRoutes = (router, withLayout = false) => {
  const routes = router.getRoutes()
  if (withLayout) {
      return routes
  }
  return () => routes.filter(route => !route.meta.isLayout)
}

export function setupLayouts(routes) {
  function deepSetupLayout(routes, top = true) {
    return routes.map(route => {
      if (route.children?.length > 0) {
        route.children = deepSetupLayout(route.children, false)
      }
      
      if (top) {
        // unplugin-vue-router adds a top-level route to the routing group, which we should skip.
        const skipLayout = !route.component && route.children?.find(r => (r.path === '' || r.path === '/') && r.meta?.isLayout)  

        if (skipLayout) {
          return route
        }

        if (route.meta?.layout !== false) {
          return { 
            path: route.path,
            component: layouts[route.meta?.layout || 'default'],
            children: route.path === '/' ? [route] : [{...route, path: ''}],
            meta: {
              isLayout: true
            }
          }
        }
      }

      if (route.meta?.layout) {
        return { 
          path: route.path,
          component: layouts[route.meta?.layout],
          children: [ {...route, path: ''} ],
          meta: {
            isLayout: true
          }
        }
      }

      return route
    })
  }

    return deepSetupLayout(routes)

}
