export class Cache {
  data = new Map<string, any>()
  loaders = new Map<string, Promise<any>>()

  async once<T>(key: string, builder: () => Promise<T>): Promise<T> {
    if (this.data.has(key)) {
      return this.data.get(key)
    }

    if (!this.loaders.has(key)) {
      this.loaders.set(key, builder())
    }

    const loader = this.loaders.get(key)
    try {
      const value = await loader
      this.data.set(key, value)
      return value
    } finally {
      this.loaders.delete(key)
    }
  }

  async clear() {
    this.data.clear()
    this.loaders.clear()
  }
}