import { useSessionStore } from "$app/stores/session"
import type { App } from "vue"

export default function(app: App) {
  const router = app.config.globalProperties.$router
  const pinia = app.config.globalProperties.$pinia

  router.beforeResolve((to, from, next) => {
    const session = useSessionStore(pinia)

    if (to.meta.auth !== false && !session.isSignedIn) {
      next({ name: 'sign_in' })
    } else {
      next()
    }
  })
}