import "reflect-metadata"
import "$vendor/fontawesome"
import "$vendor/vue-flow"
import "$ui/main.css"

import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App)

const initializers = import.meta.glob('./initializers/*.ts', { eager: true })
for (const initializer of Object.values(initializers)) {
  (initializer as any).default(app)
}

app.mount('#app')
